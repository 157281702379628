import React from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody } from '@material-ui/core';
import useStyles from './styles';
import PollState from './PollState';

const PollsTable = ({ polls, contract, setWaiting }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table sx={{ minWidth: 800 }} aria-label="caption table">
        <caption>change the status of poll, connect to your wallet to create another poll</caption>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell sx={{ color: 'blue' }}>poll&nbsp;id</TableCell>
            <TableCell align="center">poll&nbsp;name</TableCell>
            <TableCell align="center">description</TableCell>
            <TableCell align="center">status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {polls.slice(0).reverse().map((poll) => (
                      poll? (
              <TableRow key={poll.groupId}>
              <TableCell component="th" scope="row">
                {poll.groupId}
              </TableCell>
              <TableCell align="center">{poll.groupName}</TableCell>
              <TableCell align="center">{poll.groupDescription}</TableCell>
              <PollState pollId={poll.groupId} contract={contract} setWaiting={setWaiting} />
            </TableRow>
            ):(<></>)
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PollsTable
