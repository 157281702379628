import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Auth from './components/Auth/Auth';

import { Identity } from '@semaphore-protocol/identity';
import { Group } from '@semaphore-protocol/group';
import { generateProof, packToSolidityProof, verifyProof  } from '@semaphore-protocol/proof';
import Poll from './components/Poll/Poll';
import MyPolls from './components/Poll/Profile/MyPolls';

const user = new Identity();
const group = new Group();

const App = () => (
  <BrowserRouter>
    <Container maxWidth="lg">
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/auth" exact component={Auth} />
        <Route path="/poll" exact component={Poll} />
        <Route path="/mypolls" exact component={MyPolls} />
      </Switch>
    </Container>
  </BrowserRouter>
);


export default App;