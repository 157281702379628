import React, { useEffect, useState } from 'react'
import useStyles from './styles';
import { Button, Modal, TextField, Typography, Box, Paper, Card } from '@material-ui/core';
import { Identity } from '@semaphore-protocol/identity';
import axios from 'axios';
import CopyAllIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import getBlockchain from '../BlockChain/Contract';

const JoinPoll = ({ pollId, contract }) => {
  const [identity, setIdentity] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [commit, setCommit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [copy, setCoppied] = useState(false);
  const [state, setState] = useState('Ongoing');

  const user = JSON.parse(localStorage.getItem('profile'));
  const classes = useStyles();

  const getUserInfo = async () => {
    const userData = await axios.get(
      `https://devotingversion02.onrender.com/user/${user?.result._id}/userInfo`
    );
    setLoading(false);
    // console.log(userData.data.hasCommit);
    return (userData);
  };

  const createIdentity = async () => {
    setIdentity(new Identity().toString());
  };

  const join = async (e) => {
    e.preventDefault();

    // if (!commit) {
    if (state == 'Created') {

      setIsDisabled(true);
      console.log(identity);

      const currentIdentity = new Identity(identity);
      // console.log(currentIdentity)
      const commitment = currentIdentity.commitment.toString();
      console.log(currentIdentity, commitment)
      setWaiting(true);

      var CancelToken = axios.CancelToken;
      var cancel;

      await axios({
        method: 'patch',
        url: `https://devotingversion02.onrender.com/user/${user?.result._id}/commit`,
        data: {
          pollId: pollId.toString(),
          identityCommitment: commitment
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).then((newData) => console.log('success', newData)).catch(function (err) {

        if (axios.isCancel(err)) {

          console.log('im canceled');
        }
        else {
          console.log('im server response error');
        }

      });
      // this cancel the request
      cancel()

      // const newData = await axios.patch(
      //   `https://devotingversion02.onrender.com/user/${user?.result._id}/commit`,
      //   { pollId: pollId.toString(), identityCommitment: commitment }
      // );

      setWaiting(false);
    } else {
      alert('Cannot participate in!')
    }
    // } else {
    //   alert('You have already commited!')
    // }
  }

  const handleModal = async (e) => {
    e.preventDefault();
    handleOpen()
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getAStatus = async () => {
    if (contract) {
      const status = await contract.methods.getGroupStatus(pollId).call();
      console.log(status)

      if (status == 0) {
        setState('Created');
      } else if (status == 1) {
        setState('Ongoing');
      } else if (status == 2) {
        setState('Ended');
      }
    }

  }

  useEffect(() => {

    createIdentity();

    getUserInfo().then((response) => {
      setCommit(response.data.hasCommit);
      // console.log(response.data);
    });

    getAStatus();

  }, [contract])


  return (
    <div> 
      <Button color={state == 'Created' ? 'primary' : 'default' } disabled={state !== 'Created'} variant='contained' size='small' onClick={handleOpen}>join</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 500,
          backgroundColor: '#eeeeee',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Poll ID {pollId}
          </Typography>

          {
            !isDisabled ? (
              <Paper className={classes.paperJoin}>
                <form className={`${classes.root} ${classes.form}`} >
                  <Typography variant="h6">1. Register Certificate, in fact, is your bill, in the next step of voting you need it so after creation keep it safely.</Typography>
                </form>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                {/* <Button className={classes.buttonSubmit} variant="contained" size='small' color="secondary" onClick={handleClose}>cancel</Button> */}
                <Button style={{ marginLeft: '20px' }} className={classes.buttonSubmit} variant="contained" size='small' color="primary" onClick={join}>Create Register Certificate</Button>

              </Paper>
            ) : (
              <div>
                {
                  waiting ? (
                    <div>
                      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                      <Typography sx={{ marginTop: '50px', top: '50%' }} variant="h6" align="center">
                        ...pending
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Paper className={classes.paperJoin}>
                        <Typography variant="h6">copy and keep it safely</Typography>
                        <Card className={classes.card} >
                          <Typography variant="body2" color="textSecondary" component="p">{identity}</Typography>
                        </Card>
                        <CopyToClipboard text={identity}>
                          <Button size="small" color="primary" onClick={() => setCoppied(true)}>
                            <CopyAllIcon fontSize="small" /> Copy
                          </Button>
                        </CopyToClipboard>
                        <br></br><br></br><br></br>
                        <Button className={classes.buttonSubmit} disabled={!copy} variant="contained" size='small' color="secondary" onClick={handleClose}>back</Button>
                      </Paper>

                    </div>
                  )
                }
              </div>
            )
          }


        </Box>
      </Modal>
    </div>
  )
}

export default JoinPoll
