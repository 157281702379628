import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PollsTable from './PollsTable';
import { Table, Paper, TableContainer } from '@material-ui/core';
import useStyles from './styles';

const MyPolls = ({contract}) => {
  const [userPolls, setUserPolls] = useState([]);
  const [votingContract, setContract] = useState(undefined);
  const [waiting, setWaiting] = useState(false);

  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('profile'));

  const getUserPolls = async () => {

    const newData = await axios.get(
      `https://devotingversion02.onrender.com/user/${user?.result._id}/mypolls`,

    );
    // console.log(newData.data.polls);
    setUserPolls(newData.data.polls);
  };

  useEffect(() => {
    setContract(contract)
    getUserPolls();
  }, [contract])

  return (
    <div>
      {
        !waiting ? (
          <div>
            {
              votingContract ? (
                <PollsTable polls={userPolls} contract={votingContract} setWaiting={setWaiting} />
              ) : (
                <TableContainer className={classes.table} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <caption>contract not find</caption>
                  </Table>
                </TableContainer>
              )
            }
          </div>
        ) : (
          <div>
            <TableContainer className={classes.table} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <caption>...waiting</caption>
              </Table>
            </TableContainer>
          </div>
        )
      }
    </div>
  )
}

export default MyPolls