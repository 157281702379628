import React, { useEffect, useState } from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, KeyboardArrowUpIcon, KeyboardArrowDownIcon, Paper, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Collapse, Button } from '@material-ui/core';
import useStyles from './styles';
import getBlockchain from '../BlockChain/Contract';
import JoinPoll from './JoinPoll';
import PollState from './PollState';

export default function PollCard({ polls, contract }) {
  const [statuses, setStatuses] = useState([]);
  const classes = useStyles();

  console.log(contract)

  const getCandidates = async (pollId) => {
    if (contract) {
      const candidates = await contract.methods.candidatesOfPoll(1).call();
      console.log(candidates)
    }
  }

  const result = async (pollId) => {
    if (contract) {
      console.log(contract)
      const currentResult = await contract.methods.getResult().call();
      console.log(typeof (currentResult), currentResult)
      if (currentResult) {
        for (let i = 0; i < currentResult.length; i++) {
          if (ethers.utils.formatBytes32String("1") === currentResult[i]) {
            firstOption.push(currentResult[i]);
          } else if (ethers.utils.formatBytes32String("2") === currentResult[i]) {
            secondOption.push(currentResult[i]);
          }
        }
        console.log(firstOption.length);
        console.log(secondOption.length);
        setFirstOptionLenght(firstOption.length)
        setSecondOptionLenght(secondOption.length)
      }
    }
  }

  useEffect(() => {
    getCandidates(0);
  }, [contract])


  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <caption>connect to your wallet to create another poll</caption>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell sx={{ color: 'blue' }}>poll&nbsp;id</TableCell>
            <TableCell align="center">admin&nbsp;address</TableCell>
            <TableCell align="center">poll&nbsp;name</TableCell>
            <TableCell align="center">poll&nbsp;candidates</TableCell>
            <TableCell align="center">description</TableCell>
            <TableCell align="center">status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {polls.slice(0).reverse().map((poll) => (
            <TableRow key={poll.groupId}>
              <TableCell component="th" scope="row">
                {poll.groupId}
                <JoinPoll pollId={poll.groupId} contract={contract} />
              </TableCell>
              {
                poll.coordinator == "0xA09945A8bc3B65FC4580CD020CaaD54360a322a4" ? (<TableCell align="center">server</TableCell>) : (<TableCell align="center">{poll.coordinator}</TableCell>)
              }
              <TableCell align="center">{poll.groupName}</TableCell>
              <TableCell align="center">{poll.groupCandidates.map((c) => { return ` ${c} ,` })}</TableCell>
              <TableCell align="center">{poll.groupDescription}</TableCell>
              <PollState pollId={poll.groupId} contract={contract} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

