
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Paper, Container, Link, Card, Grid, Grow, TextField } from '@material-ui/core';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';
import getBlockchain from '../BlockChain/Contract';
import Poll from '../Poll/Poll';

const users = [];

const Form = () => {
  const [commit, setCommit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(undefined);
  const [handlePoll, setHandlePoll] = useState(false);

  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const location = useLocation();

  const getUserInfo = async () => {
    const userData = await axios.get(
      `https://devotingversion02.onrender.com/user/${user?.result._id}/userInfo`
    );
    setLoading(false);
    // console.log(userData.data.hasCommit);
    return (userData);
  };

  useEffect(() => {
    getUserInfo().then((response) => {
      setCommit(response.data.hasCommit);
      console.log(response.data);
    });
    const init = async () => {
      const { contract } = await getBlockchain();
      setContract(contract);
      console.log(contract)

    }
    init();

  }, [location, commit]);

  if (!user?.result?.name) {
    return (
      <Container component="main" maxWidth="lg">
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            Wellcome, Please Signin
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="lg">
      <Paper className={classes.paper} elevation={3}>
        {loading ? (
          <>
            loading...
          </>
        ) : (
          <>  
                <Poll contract={contract}/>              
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Form;


