import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Button, Typography, Box, Modal, Paper, Container, TextField, Grid } from '@material-ui/core';
import axios from 'axios';
import PollCard from './PollCard';
import getBlockchain from '../BlockChain/Contract';
import ConnectMetamask from '../BlockChain/ConnectMetamask';
import { useLocation } from 'react-router-dom';
import MyPolls from './Profile/MyPolls';
import Loading from 'react-simple-loading';

const Admin = ({ contract }) => {
    const [pollId, setPollId] = useState();
    const [waiting, setWaiting] = useState(false);
    const [polls, setPolls] = useState([]);
    const [votingContract, setContract] = useState(undefined);
    const [candidateArrey, setCandidateArrey] = useState([]);
    const [adminAddress, setAdminAddress] = useState('');
    const [pollName, setPollName] = useState('');
    const [pollDescription, setPollDescription] = useState('');
    const [notExist, setNotExist] = useState(true);
    const [handleProfile, setHandleProfile] = useState(false);
    const [pending, setPending] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [isConnected, setIsConnected] = useState(Boolean);

    console.log(contract)

    const classes = useStyles();
    const location = useLocation();

    const user = JSON.parse(localStorage.getItem('profile'));

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleModal = async (e) => {
        e.preventDefault();
        handleOpen()
    }

    const createGroup = async (e) => {
        e.preventDefault();

        if (votingContract) {
            setPending(true)
            await votingContract.methods.createPoll(
                "0xA09945A8bc3B65FC4580CD020CaaD54360a322a4", '20', pollName, pollDescription, candidateArrey
            ).send({ from: accounts[0] }).then(async (instance) => {
                const pollId = instance.events.PollCreated.returnValues[0];

                var CancelToken = axios.CancelToken;
                var cancel;

                await axios({
                    method: 'patch',
                    url: `https://devotingversion02.onrender.com/user/${user?.result._id}/createPoll`,
                    data: {
                        pollId: pollId,
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    })
                }).then((newData) => console.log('success', newData)).catch(function (err) {

                    if (axios.isCancel(err)) {

                        console.log('im canceled');
                    }
                    else {
                        console.log('im server response error');
                    }

                });
                // this cancel the request
                cancel()
                alert(`poll id ${pollId} created`);
                window.location.reload();
            }).catch((error) => {
                alert(error.message);
                setPending(false)
            })
        } else {
            alert('contract did not find!')
        }
    };

    const contractExist = async () => {
        if (votingContract) {
            console.log('hey')
            setNotExist(false)
        }
    }

    const getGroups = async () => {
        const newData = await axios.get(
            `https://devotingversion02.onrender.com/user/getGroups`,

        );
        console.log(newData.data.allGroups);
        setPolls(newData.data.allGroups);
    };

    useEffect(() => {
        setContract(contract)
        contractExist();
        getGroups();
    }, [location, contract]);

    console.log(votingContract)

    return (
        <div>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper}>
                    <Typography variant="h4" align="center">
                        Polls
                    </Typography>
                    <br></br>
                    <div>
                        {waiting ? (
                            <Typography variant="h6" align="center">
                                ...waiting
                            </Typography>
                        ) : (
                            <div>
                                {
                                    polls?.length > 0 ? (
                                        <div>
                                            {
                                                votingContract ? (
                                                    <div>
                                                        {
                                                            !handleProfile ? (<PollCard polls={polls} contract={votingContract} />) : (<MyPolls contract={votingContract} />)
                                                        }
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="empty">
                                                            <h2>No Contract found</h2>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="empty">
                                                <h2>No poll found</h2>
                                            </div>
                                        </div>
                                    )
                                }

                                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleModal}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Button variant="contained" className={classes.logout} color="primary" onClick={() => setHandleProfile(!handleProfile)}>{handleProfile ? "All Polls" : "My Polls"}</Button>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ConnectMetamask accounts={accounts} setAccounts={setAccounts} setIsConnected={setIsConnected} />
                                        </Grid>
                                    </Grid>
                                    {
                                        votingContract ? (
                                            <div>
                                                <Button disabled={!isConnected} className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth onClick={handleOpen}>create new poll</Button>
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: 400,
                                                        height: 600,
                                                        backgroundColor: '#eeeeee',
                                                        border: '2px solid #000',
                                                        boxShadow: 24,
                                                        p: 4,
                                                    }}>


                                                        {
                                                            pending ? (
                                                                <div>
                                                                    <Typography  align='center' variant='h4' component='h1' >
                                                                        ...pending
                                                                    </Typography>
                                                                    <Loading size={'100px'}/>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <Typography id="modal-modal-title" variant="h5" component="h2">
                                                                        Poll Form
                                                                    </Typography>
                                                                    <Paper className={classes.paper}>
                                                                        <form className={`${classes.root} ${classes.form}`} >
                                                                            <Typography variant="h6"></Typography>
                                                                            <TextField name="title" variant="outlined" label="name" fullWidth onChange={(e) => setPollName(e.target.value.toString())} />
                                                                            <TextField name="message" variant="outlined" label="description" fullWidth multiline rows={4} onChange={(e) => setPollDescription(e.target.value.toString())} />
                                                                            <TextField name="tags" variant="outlined" label="candidate (coma separated)" fullWidth onChange={(e) => setCandidateArrey(e.target.value.split(','))} />
                                                                        </form>
                                                                    </Paper>

                                                                    <br></br>
                                                                    <Button className={classes.buttonSubmit} variant="contained" size='small' color="secondary" onClick={handleClose}>cancel</Button>
                                                                    <Button style={{ marginLeft: '20px' }} className={classes.buttonSubmit} variant="contained" size='small' color="primary" onClick={createGroup}>comfirm</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </Box>
                                                </Modal>
                                            </div>
                                        ) : (
                                            <Typography>
                                                contrat did not find!(refresh)
                                            </Typography>
                                        )
                                    }
                                </form>
                            </div>
                        )}
                    </div>
                </Paper>
            </Container>

        </div>
    )
}

export default Admin;



// const createGroup = async (e) => {
//     e.preventDefault();

//     setWaiting(true);


//     var CancelToken = axios.CancelToken;
//     var cancel;
//     var newData;

//     await axios({
//         method: 'post',
//         url: `https://devotingversion02.onrender.com/user/createPoll`,
//         data: {
//             coordinator: "0xA09945A8bc3B65FC4580CD020CaaD54360a322a4",
//             merkleTreeDepth: "20"
//         },
//         cancelToken: new CancelToken(function executor(c) {
//             // An executor function receives a cancel function as a parameter
//             cancel = c;
//         })
//     }).then((response) => {
//         newData = response
//         console.log('success', newData)
//     }).catch(function (err) {

//         if (axios.isCancel(err)) {

//             console.log('im canceled');

//         }
//         else {

//             console.log('im server response error');

//         }

//     });
//     // this cancel the request
//     cancel()

//     // const userData = await axios.post(
//     //     `https://devoting.onrender.com/user/createPoll`,
//     //     {
//     //         pollId: pollId.toString(),
//     //         coordinator: "0xA09945A8bc3B65FC4580CD020CaaD54360a322a4",
//     //         merkleTreeDepth: "20"
//     //     }
//     // )

//     setWaiting(false);

//     console.log(newData)

//     if (newData) {
//         alert(newData.data.message)
//         return (newData);
//     }
// };