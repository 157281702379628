import React, { useEffect, useState } from 'react'
import { TableCell, Button } from '@material-ui/core';
import useStyles from './styles';
import axios from 'axios';

const PollState = ({ pollId, contract, setWaiting }) => {
  console.log(contract)

  const [state, setState] = useState('...');

  const classes = useStyles();

  const startGroup = async (e) => {
    e.preventDefault();

    setWaiting(true);
    const userData = await axios.post(
      `https://devotingversion02.onrender.com/user/startPoll`,
      {
        pollId: pollId.toString(),
        encryptionKey: "123",
      }
    )
    setWaiting(false);

    alert(userData.data.message)
    return (userData);
  };

  const endGroup = async (e) => {
    e.preventDefault();

    setWaiting(true);
    const userData = await axios.post(
      `https://devotingversion02.onrender.com/user/endPoll`,
      {
        pollId: pollId.toString(),
        decryptionKey: "123",
      }
    )
    setWaiting(false);

    alert(userData.data.message)
    return (userData);
  };

  const getAStatus = async () => {
    if (contract) {
      const status = await contract.methods.getGroupStatus(pollId).call();

      console.log(status)

      if (status == 0) {
        setState('Created');
      } else if (status == 1) {
        setState('Ongoing');
      } else if (status == 2) {
        setState('Ended');
      }
    }
  }

  useEffect(() => {
    getAStatus();
  }, [contract])

  return (
    <TableCell align="center">
      {
        state
      }
      {
        state == "Created" ? (
          <div>
            <Button variant="contained" size="small" className={classes.logout} onClick={startGroup} >Ongoing</Button>
          </div>
        ) : (
          <div>
            <Button variant="contained" size="small" className={classes.logout} onClick={endGroup} >Ended</Button>
          </div>
        )
      }
    </TableCell>
  )
}

export default PollState